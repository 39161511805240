<template>
  <div class="wrap">
    <div class="banner" :style="{backgroundImage: 'url(' + imgUrl.banner + ')'}">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">病区无扰式24H生命体征监护系统与患者健康管理系统</p>
      </div>
    </div>
    <div class="content">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>产品<em>介绍</em></h2>
        <div class="details">
          <div class="text bg arrow-right">
            <p> 无扰式生命体征监护仪是一种具有无创血压(NIBP)、脉搏率、平均动脉压(MAP)、血氧饱和度(SpO2)、体温监护等功能的仪器。</p>
            <p>产品适用范围该产品具有无创血压(NIBP)、脉搏率、平均动脉压(MAP)、血氧饱和度(SpO2)、体温监护的功能，可用于供医师和具有医疗资格的人员对新生儿、小儿及成人患者进行无创血压、脉搏率、体温、无创动脉血氧饱和度(SpO2)以及在正常模式和腋窝模式下体温的监护。该产品可在医疗/外科病房、医院、或者其他监护环境下使用。该产品仅供医生或者具有资格的医护人员使用。</p>
          </div>
          <div class="img">
            <img :src="imgUrl.img1"
                 alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="content bg">
      <div class="inner product">
        <div class="details2">病区无扰式24H生命体征监护系统与患者健康管理系统，以病床无扰式生命体征采集与睡眠呼吸监测终端与病区中央生命体征监护中心为核心系统，实现病床24小时非穿戴式、无扰式生命体征采集与监控系统可实现非正常生命体征自动报警，非正常离床报警等功能，并对患者每日生命体征数据通过AI大数据分折给出患者在院每日健康评价,并可做为医生诊断的辅助信息。离院VIP患者也能实现离院健康管理。</div>
        <div class="img2"><img :src="imgUrl.img2"
               alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
import getAliyunImg from '../../utils/aliyun'

export default {
  name: 'doctor',
  created () {
    this.imgUrl.banner = getAliyunImg.signatureUrl('ytwd/gw/unperturbed-banner.png')
    this.imgUrl.img1 = getAliyunImg.signatureUrl('ytwd/gw/unperturbed-img1.png')
    this.imgUrl.img2 = getAliyunImg.signatureUrl('ytwd/gw/unperturbed-img4.png')
  },
  data () {
    return {
      imgUrl: {
        banner: '',
        img1: '',
        img2: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./css/style.scss";
.banner {
  // background-image: url("../../assets/images/unperturbed-banner.png");
  .icon {
    background-image: url("../../assets/images/unperturbed-icon.png");
  }
}
.content {
  &.bg {
    background-color: #edfffe;
    padding: 50px 0 100px;
  }
  .product {
    .details {
      margin-bottom: 45px;
      .text {
        width: 505px;
        align-self: center;
        min-height: 300px;
        &.bg {
          background-size: 210px 207px;
        }
      }
      .img {
        background: none;
        flex: 1;
      }
    }
    .details2 {
      width: 796px;
      padding: 20px 30px;
      background: #fff;
      font-size: 16px;
      line-height: 36px;
      color: #6a6a6a;
      margin: 0 auto 100px;
      box-shadow: 1px 1px 24px 0px rgba(0, 209, 201, 0.28);
    }
  }
}
</style>
